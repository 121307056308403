import React from 'react';

const CricketPoster = () => {
  return (
    <div className="relative h-screen flex items-center  justify-center ">
      <img
        src="/cricket poster.jpg"
        alt="Bonus Image"
        className="w-full h-full object-contain"
      />
      <p className="text-white uppercase absolute bottom-60 md:bottom-40 bg-yellow-500 px-5 py-2  rounded-md font-semibold mt-2 text-xs sm:text-sm md:text-lg">
        Get a 100$ B0nus
      </p>
      <div className="absolute flex flex-col items-center justify-center bottom-40 md:bottom-10 z-10">
        <a href="https://wa.link/interaktadsgoogle">
          <button className="bg-gradient-to-r from-yellow-400 via-orange-500 to-red-500 font-semibold uppercase rounded-md px-6 py-2 md:px-10 md:py-3 text-white animate-bounce mt-4 text-sm md:text-lg">
            Claim Now
          </button>
        </a>
      </div>
    </div>
  );
};

export default CricketPoster;